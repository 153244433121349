import { MiniAppServiceError } from "../config/error"

export default class BaseService {
  protected timeout: number
  protected baseURL: string
  protected appId: string

  constructor({ timeout, baseURL, appId }: ServiceInitParams) {
    this.timeout = timeout
    this.baseURL = baseURL
    this.appId = appId
  }

  protected async request<T>(
    url: string,
    fetchOptions: FetchOptions = { method: 'POST' },
  ): Promise<T> {
    // docs https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
    const uri = new URL(url, this.baseURL).href

    console.log('request', uri)

    if (this.appId) {

      fetchOptions.headers = {
        ...fetchOptions.headers,
        'x-api-key': this.appId,
      }
    }
    try {
      const response = await fetch<T>(uri, fetchOptions)
      const json = await response.json<any>()
      if (response.ok) {
        return json
      }
      return Promise.reject(
        new MiniAppServiceError('BAD_REQUEST', {
          cause: JSON.stringify(json),
        })
      )
    } catch (error: any) {
      return Promise.reject(
        new MiniAppServiceError('BAD_REQUEST', {
          cause: JSON.stringify(error),
        })
      )
    }
  }
}
