const AppErrorCode = new Map<string, string[]>()

// SDK Core Error
AppErrorCode.set('PLATFORM_FEATURE_MISSING_IOS_WEBKIT_MESSAGE_HANDLERS', [
  'C005',
  'Not implemented window webkit messageHandlers',
])
AppErrorCode.set('VALIDATION_ERROR', ['C006', 'validation error'])
AppErrorCode.set('NOT_FOUND_ASSET_ID', ['C006', 'Not found asset id'])
AppErrorCode.set('NOT_FOUND_PAYLOAD', ['C007', 'Not found payload'])

// Service Error ------------------------------------------------------
AppErrorCode.set('BAD_REQUEST', [
  'S001',
  'The server cannot or will not process the request',
])



// Main classes ---------------------------------------------------------
class BaseError extends Error {
  protected errorCode: string
  protected cause: string
  constructor(message: string, params: any) {
    super(message)
    this.errorCode = params.errorCode
    this.cause = params.cause || ''
    this.name = params.name || 'Error'

    console.error(this.toString())
  }

  public toString() {
    return `[${this.errorCode}:${this.name}] – ${this.message} ${this.cause}`
  }
}

export class MiniAppCoreError extends BaseError {
  constructor(errorKey: string, params: any = {}) {
    const errors = AppErrorCode.get(errorKey) as string[]
    const [errorCode, errorMessage] = errors
    const _params = { errorCode, name: 'MiniAppCoreError', ...params }
    super(errorMessage, _params)
  }
}

export class MiniAppServiceError extends BaseError {
  constructor(errorKey: string, params: any = {}) {
    const errors = AppErrorCode.get(errorKey) as string[]
    const [errorCode, errorMessage] = errors
    const _params = { errorCode, name: 'MiniAppServiceError', ...params }
    super(errorMessage, _params)
  }
}
