// TODO split files
export class AppConfig {
  public static GATEWAY_BASE_URL = 'http://dev-gateway-api.ampverse.com/'
}

class DevelopmentConfig extends AppConfig {
  public static GATEWAY_BASE_URL = 'http://dev-gateway-api.ampverse.com/'
}

class ProductionConfig extends AppConfig {
  public static GATEWAY_BASE_URL = 'http://dev-gateway-api.ampverse.com/'
}

export default function getConfig(
  env: ConfigENV = 'PRODUCTION'
): typeof AppConfig {
  switch (env) {
    case 'PRODUCTION':
      return ProductionConfig
    case 'DEVELOPMENT':
      return DevelopmentConfig
    default:
      return DevelopmentConfig
  }
}
