import BaseService from './base'

/**
 * Provides user-related functionalities for the AmpverseSDK.
 */
export default class UserService extends BaseService {
  private serviceName = 'users-service'

  /**
   * Initializes a new instance of the UserService.
   * @param config - The configuration parameters for service initialization.
   */
  constructor(config: ServiceInitParams) {
    const { timeout, baseURL, appId } = config
    super({ timeout, baseURL, appId })
  }

  /**
   * Tracks user login events.
   *
   * Sends a POST request to the users-service endpoint to log a user login event.
   *
   * @param loginData - The login data payload containing user information.
   * @returns A promise resolving to the response of the login tracking request.
   */
  public trackLogin(loginData: TrackLoginPayload): Promise<unknown> {
    return this.request<any>(`${this.serviceName}/v1/users/login`, {
      method: 'POST',
      body: JSON.stringify(loginData),
      headers: { 'Content-Type': 'application/json' },
    })
  }

  /**
   * Tracks user registration events.
   *
   * Sends a POST request to the users-service endpoint to log a user registration event.
   *
   * @param registerData - The registration data payload containing user information.
   * @returns A promise resolving to the response of the registration tracking request.
   */
  public trackRegister(registerData: TrackRegisterPayload): Promise<unknown> {
    return this.request<any>(`${this.serviceName}/v1/users/register`, {
      method: 'POST',
      body: JSON.stringify(registerData),
      headers: { 'Content-Type': 'application/json' },
    })
  }
}
